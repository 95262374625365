<template>
  <div class="menu tabs-menu widget">
    <label class="label">
      {{ $t("common.menu") }}
    </label>

    <v-tabs vertical class="card py-4">
      <template v-for="item in items">
        <v-tab
          v-if="!item.hidden"
          :key="item.name"
          :to="item.route || null"
          :disabled="item.disabled"
        >
          <v-icon color="white" size="26">
            {{ item.icon }}
          </v-icon>
          {{ item.name }}
        </v-tab>
      </template>
    </v-tabs>
  </div>
</template>

<script>
import { $env, roleCheck } from "@/utils/utils";
import store from "@/store";

export default {
  data() {
    return {
      items: [
        {
          name: this.$t("common.feed"),
          icon: "mdi-home",
          route: { name: "app" },
        },
        {
          name: this.$t("users.followers"),
          icon: "mdi-account-multiple",
          route: { name: "app.follows.followers" },
        },
        {
          name: this.$t("common.groups"),
          icon: "mdi-account-group",
          route: { name: "app.groups" },
        },
        {
          name: this.$t("common.wallet"),
          icon: "mdi-wallet",
          route: { name: "app.wallet" },
          hidden: $env.prod,
        },
        {
          name: this.$t("contacts.book"),
          icon: "mdi-card-account-details",
          route: { name: "app.contacts" },
        },
        {
          name: this.$t("common.dashboard"),
          icon: "mdi-view-dashboard",
          route: { name: "admin" },
          hidden: !roleCheck("ROLE_ADMIN", store.getters["auth/user"]),
        },
      ],
    };
  },
};
</script>
